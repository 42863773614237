<template>
  <div>
    <div class="placeholder-paragraph aligned-centered pd-y-70" v-if="loading">
      <div class="line"></div>
      <div class="line"></div>
    </div>

    <div v-if="!loading && !isSignedIn" class="text-center">
      <button class="btn btn-danger" v-on:click="login">Login Youtube</button>
    </div>

    <div v-if="!loading && isSignedIn">
      <table class="table" v-if="videos && videos.length">
      <thead>
      <tr>
        <th scope="col">Title</th>
        <th scope="col">Date</th>
        <th scope="col">Thumbnail</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="{ id, snippet } in videos" v-bind:key="snippet.resourceId.videoId" v-bind:class="{'table-secondary': hasSource(id)}">
        <td>{{ snippet.title }}</td>
        <td>{{ snippet.publishedAt | format_date }}</td>
        <td>
          <a v-bind:href="videoUrl(snippet)" target="_blank">
            <img v-bind:src="snippet.thumbnails.default.url">
          </a>
        </td>
        <td class="text-right">
          <button class="btn btn-sm btn-dark btn-block" v-on:click="importVideo(id, snippet)" v-if="!hasSource(id)">Import</button>
          <router-link class="btn btn-sm btn-secondary btn-block" :to="{name: 'accounts.sermons.edit', params: {id: sermonBySource(id).id}}" v-if="hasSource(id)">Edit</router-link>
        </td>
      </tr>
      </tbody>
    </table>
    </div>
  </div>
</template>

<script>
  import Form from 'vform'
  import moment from 'moment'
  import { mapGetters } from 'vuex';

  export default {
    data: () => ({
      loading: true,
      isSignedIn: false,
      isApiLoaded: false,
      playListId: null,
      nextPageToken: null,
      prevPageToken: null,
      videos: []
    }),
    created () {
      let vm = this;
      this.$store.dispatch('account/fetchSermons').then(() => { 
        vm.$gapi.getAuthInstance().then((auth) => {
          if (auth.isSignedIn.get()) {
            vm.isSignedIn = true;
            vm.loadApi();
          } else {
            vm.loading = false;
          }
        });
      });
    },
    computed: mapGetters({
      hasSource: 'account/hasSource',
      sermonBySource: 'account/sermonBySource'
    }),
    methods: {
      videoUrl(snippet) {
        return "https://www.youtube.com/watch?v=" + snippet.resourceId.videoId;
      },

      // Load the client interfaces for the YouTube Analytics and Data APIs, which
      // are required to use the Google APIs JS client. More info is available at
      // https://developers.google.com/api-client-library/javascript/dev/dev_jscript#loading-the-client-library-and-the-api
      loadApi() {
        let vm = this;
        // if (!this.isApiLoaded) {
          this.$gapi.getGapiClient().then(({client}) => {
            console.log("Loading Youtube");
            client.load('youtube', 'v3', function() {
              vm.onApiLoaded();
            });  
          });
        // } else {
        //   vm.onApiLoaded();
        // }
      },
      onApiLoaded() {
        console.log("API Loaded Youtube");
        this.loading = false;
        this.playListId = null;
        this.nextPageToken = null;
        this.prevPageToken = null;
        this.requestUserUploadsPlaylistId();
      },
      
      // Call the Data API to retrieve the playlist ID that uniquely identifies the
      // list of videos uploaded to the currently authenticated user's channel.
      requestUserUploadsPlaylistId() {
        let vm = this;
        this.$gapi.getGapiClient().then(({client}) => {
          // See https://developers.google.com/youtube/v3/docs/channels/list
          var request = client.youtube.channels.list({
            mine: true,
            part: 'contentDetails'
          });
          request.execute(function(response) {
            vm.playlistId = response.result.items[0].contentDetails.relatedPlaylists.uploads;
            vm.requestVideoPlaylist(vm.playlistId);
          });
        });
      },

      // Retrieve the list of videos in the specified playlist.
      requestVideoPlaylist(playlistId, pageToken) {
        let vm = this;
        this.videos = [];
        this.$gapi.getGapiClient().then(({client}) => {
          var requestOptions = {
            playlistId: playlistId,
            part: 'snippet',
            maxResults: 10
          };
          if (pageToken) {
            requestOptions.pageToken = pageToken;
          }
          var request = client.youtube.playlistItems.list(requestOptions);
          request.execute(function(response) {
            // Only show pagination buttons if there is a pagination token for the
            // next or previous page of results.
            vm.nextPageToken = response.result.nextPageToken;
            vm.prevPageToken = response.result.prevPageToken;
            vm.videos = response.result.items;
            console.log(vm.videos);
          });
        });
      },

      // Attempt the OAuth 2.0 client flow when the user clicks the button
      login() {
        let vm = this;
        vm.isSignedIn = false;

        this.$gapi.getAuthInstance().then((auth) => {
          if (auth.isSignedIn.get()) {
            let user = auth.currentUser.get();
            console.log(user);
            vm.isSignedIn = true;
            vm.loadApi();
          } else {
            auth.signIn().then((user) => {
              console.log(user);
              
              if (user && !user.error) {
                vm.isSignedIn = true;
                vm.loadApi();
              }
            });
          }
        });
      },

      // Attempt to import the seelcted video and edit it
      async importVideo(id, snippet) {
        let thumbnail = snippet.thumbnails.default;
        if (snippet.thumbnails.maxres) {
          thumbnail = snippet.thumbnails.maxres;
        } else if (snippet.thumbnails.standard) {
          thumbnail = snippet.thumbnails.standard;
        }

        let form = new Form({
          source: 'youtube',
          source_id: id,
          videoUrl: "https://www.youtube.com/watch?v=" + snippet.resourceId.videoId,
          thumbnailUrl: thumbnail.url,
          title: snippet.title,
          date: moment(String(snippet.publishedAt)).format('YYYY-MM-DD'),
          description: snippet.description
        });

        const { data } = await form.submit('post', '/api/v1/user/me/sermons/import');
        
        this.$store.dispatch('account/updateSermons', { sermon: data.sermon })
          .then(() => this.$router.push({ name: 'accounts.sermons.edit', params: {id: data.sermon.id }}))
      }
    }
  }
</script>
